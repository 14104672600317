import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import Layout from "../../components/layout";
import Loading from "../../components/loading";
import Pagination from "../../components/pagination";
import { ErrMsg } from "../../components/message";
import ConfirmBox from "../../components/confirmBox";
import { DefMonthPicker } from "../../components/datepicker";

import { CurrentUserContext } from "../../providers/auth";
import Services, {
    isUnauthorizedError,
    isForbiddenError
} from "../../services";
import { formatDate, formatDateStr, timeFormats } from "../../utils/time";
import lamppostUtil, { lamppostDisplayName } from "../../utils/lamppost";
import UserUtil from "../../utils/user";
import Links from "../../utils/links";

const DataValidationPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            link={Links.Validation}
            seoTitle="Data Validation Overview"
            permissionValidator={UserUtil.hasDataOperatorPrivileges}
            forceCheckSessionCount={checkCount}
        >
            <DataValidation forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default DataValidationPage;

const { RAW, INTERIM, APPROVING, APPROVED, REJECTED } = lamppostUtil.statusDict;
const ALL = "All";
const OPTIONS_PREFIX = "dv-filter-options.";
const MONTH = "MONTH";

const formatMonthStr = (month) =>
    formatDateStr(month, timeFormats.YYYYMM, timeFormats.month);

const getFilterOptions = (userProfile = null) => {
    const defOptions = {
        sortBy: MONTH,
        status: getDefStatus(userProfile),
        lamppostId: ALL,
        from: null,
        to: null
    };
    if (!userProfile) {
        return defOptions;
    }
    const options = window.localStorage.getItem(
        OPTIONS_PREFIX + userProfile.user_type
    );
    if (options) {
        try {
            let po = JSON.parse(options);
            if (po.from) {
                po.from = new Date(po.from);
            }
            if (po.to) {
                po.to = new Date(po.to);
            }
            return po;
        } catch (err) {
            console.error(err);
        }
    }
    return defOptions;
};

const saveFilterOptions = (userProfile, options) =>
    userProfile &&
    window.localStorage.setItem(
        OPTIONS_PREFIX + userProfile.user_type,
        JSON.stringify(options)
    );

const getDefStatus = (userProfile) => {
    let status = lamppostUtil.statusList.reduce((dict, s) => {
        dict[s] = true;
        return dict;
    }, {});
    if (UserUtil.isDataOperator(userProfile)) {
        status[APPROVING] = false;
        status[APPROVED] = false;
    } else if (UserUtil.isSysAdmin(userProfile)) {
        status[RAW] = false;
    }
    return status;
};

const DataValidation = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const lamppostService = Services(currentUser).lamppost;

    const options = getFilterOptions();
    // States for fitering: status, sortBy, lamppostId, from, to
    const [status, setStatus] = useState(options.status);
    const [sortBy, setSortBy] = useState(options.sortBy);
    const [lamppostId, setLamppostId] = useState(options.lamppostId);
    const [from, setFrom] = useState(options.from);
    const [to, setTo] = useState(options.to);

    const [loading, setLoading] = useState(false);
    const [lampposts, setLampposts] = useState([]);
    const [lamppostDict, setLamppostDict] = useState(null);
    const [datasets, setDatasets] = useState(null);
    const [userProfile, setUserProfile] = useState(null);

    const [confirmDataset, setConfirmDataset] = useState({
        idx: null,
        dataset: {}
    });
    const [confirmSubmit, setConfirmSubmit] = useState(false);
    const [confirmWithdraw, setConfirmWithdraw] = useState(false);

    const getDatasetsParams = ({
        curPage = 1,
        options = { sortBy, status, lamppostId, from, to }
    }) =>
        [
            "page=" + curPage,
            "sort=" + options.sortBy,
            "desc=" +
                (options.sortBy === "STATUS" ||
                options.sortBy === "LAST_UPDATED_BY"
                    ? "0"
                    : "1")
        ]
            .concat(
                Object.keys(options.status)
                    .filter((s) => options.status[s] === true)
                    .map((s) => "status=" + s)
            )
            .concat(
                options.lamppostId === ALL
                    ? []
                    : ["lamppost_id=" + options.lamppostId]
            )
            .concat(
                options.from !== null
                    ? ["from=" + formatDate(options.from, timeFormats.YYYYMM)]
                    : []
            )
            .concat(
                options.to !== null
                    ? ["to=" + formatDate(options.to, timeFormats.YYYYMM)]
                    : []
            );

    const resetOptions = () => {
        setStatus(getDefStatus(userProfile));
        setSortBy("MONTH");
        setLamppostId(ALL);
        setFrom(null);
        setTo(null);
    };

    const applyFilter = () => {
        saveFilterOptions(userProfile, {
            sortBy,
            status,
            lamppostId,
            from,
            to
        });
        changePage(1);
    };

    const handleAuthError = (err) => {
        if (isUnauthorizedError(err) || isForbiddenError(err)) {
            forceCheckSession();
        }
    };

    const changePage = (curPage) => {
        setLoading(true);
        setDatasets(null);
        lamppostService
            .getMetricDatasets(getDatasetsParams({ curPage }))
            .then((d) => {
                setDatasets(d);
            })
            .catch((err) => {
                handleAuthError(err);
                setDatasets(null);
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const updateDataset = (idx, newDataset) => {
        let newDatasets = Object.assign({}, datasets);
        newDatasets.datasets.map((d) => Object.assign({}, d));
        newDatasets.datasets[idx] = Object.assign({}, newDataset);
        setDatasets(newDatasets);
    };

    const submitApproval = (idx, dataset) => {
        setConfirmDataset({ idx, dataset });
        setConfirmSubmit(true);
    };
    const handleSubmitApproval = ({ idx, dataset }) => {
        lamppostService
            .submitMetricDatasetApproval(dataset.lamppost_id, dataset.month)
            .then(() =>
                lamppostService.getMetricDatasets(
                    getDatasetsParams({ curPage: datasets.curr_page })
                )
            )
            .then((d) => setDatasets(d))
            .catch((err) => {
                handleAuthError(err);
                dataset.statusErr =
                    err.message || "Cannot submit for approval.";
                updateDataset(idx, dataset);
            })
            .finally(() => setConfirmSubmit(false));
    };

    const withdrawApproval = (idx, dataset) => {
        setConfirmDataset({ idx, dataset });
        setConfirmWithdraw(true);
    };
    const handleWithdrawApproval = ({ idx, dataset }) => {
        lamppostService
            .withdrawMetricDatasetApproval(dataset.lamppost_id, dataset.month)
            .then(() =>
                lamppostService.getMetricDatasets(
                    getDatasetsParams({ curPage: datasets.curr_page })
                )
            )
            .then((d) => setDatasets(d))
            .catch((err) => {
                handleAuthError(err);
                dataset.statusErr = err.message || "Cannot withdraw submitted.";
                updateDataset(idx, dataset);
            })
            .finally(() => setConfirmWithdraw(false));
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        setLoading(true);
        currentUser.getUser().then((u) => {
            if (u) {
                setUserProfile(u.profile);
                const options = getFilterOptions(u.profile);
                setStatus(options.status);
                setSortBy(options.sortBy);
                setLamppostId(options.lamppostId);
                setFrom(options.from);
                setTo(options.to);
                lamppostService
                    .getLampposts()
                    .then((d) => {
                        setLamppostDict(
                            d.lampposts.reduce((dict, l) => {
                                dict[l.lamppost_id] = l;
                                return dict;
                            }, {})
                        );
                        setLampposts(d.lampposts);
                    })
                    .catch((err) => {
                        handleAuthError(err);
                        setLamppostDict(null);
                    });
                lamppostService
                    .getMetricDatasets(getDatasetsParams({ options }))
                    .then((d) => setDatasets(d))
                    .catch((err) => {
                        handleAuthError(err);
                        setDatasets(null);
                        console.error(err);
                    })
                    .finally(() => setLoading(false));
            }
        });
    }, [currentUser]);

    const Options = () => {
        const updateStatus = (statusName) => {
            let newStatus = Object.assign({}, status);
            newStatus[statusName] = !newStatus[statusName];
            setStatus(newStatus);
        };
        return (
            <div className="">
                <div
                    className="inline-block mr-16 lg:text-right"
                    style={{ width: "300px" }}
                >
                    <span className="label">Sort By:</span>
                    <select
                        className="input-inline"
                        style={{ width: "200px" }}
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                    >
                        {lamppostUtil.sortings.map((s, idx) => {
                            return (
                                <option key={idx} value={s.value}>
                                    {s.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="mt-3 inline-block">
                    <span className="label">Status:</span>
                    {lamppostUtil.statusList.map((s, idx) => (
                        <div key={idx} className="inline-block mr-4">
                            <label>
                                <input
                                    className="mr-1"
                                    type="checkbox"
                                    checked={status[s]}
                                    onChange={() => updateStatus(s)}
                                />
                                {s}
                            </label>
                        </div>
                    ))}
                </div>
                <br />
                <div
                    className="mt-3 inline-block mr-16 lg:text-right"
                    style={{ width: "300px" }}
                >
                    <span className="label">Lamppost:</span>
                    <select
                        className="input-inline"
                        style={{ width: "200px" }}
                        value={lamppostId}
                        onChange={(e) => setLamppostId(e.target.value)}
                    >
                        {[{ name: ALL, lamppost_id: ALL }]
                            .concat(lampposts)
                            .map((l, idx) => {
                                return (
                                    <option key={idx} value={l.lamppost_id}>
                                        {idx === 0
                                            ? l.name
                                            : lamppostDisplayName(l)}
                                    </option>
                                );
                            })}
                    </select>
                </div>
                <div className="mt-3 inline-block">
                    <div className="flex flex-row items-center">
                        <span className="label">Months:</span>
                        <DefMonthPicker
                            selected={from}
                            onChange={(d) => setFrom(d)}
                        />
                        <span className="mx-4">to</span>
                        <DefMonthPicker
                            selected={to}
                            onChange={(d) => setTo(d)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="title-h1">Data Validation Overview</div>
            <div className="lg:flex lg:flex-row p-4 bg-gray-200 mb-4">
                {lampposts && <Options />}
                <div className="lg:flex-1" />
                <div
                    className="lg:flex lg:flex-col mt-3 text-center"
                    style={{ minWidth: "150px" }}
                >
                    <button className="btn-primary mx-2" onClick={applyFilter}>
                        <span className="px-4 inline-block">Apply Filter</span>
                    </button>
                    <button
                        className="btn-secondary mt-3 mx-2"
                        onClick={resetOptions}
                    >
                        Reset Options
                    </button>
                </div>
            </div>
            {loading && <Loading />}
            {lamppostDict && datasets && userProfile && (
                <>
                    {datasets.datasets.length !== 0 ? (
                        <DatasetsTable
                            userProfile={userProfile}
                            datasets={datasets}
                            lamppostDict={lamppostDict}
                            submitApproval={submitApproval}
                            withdrawApproval={withdrawApproval}
                        />
                    ) : (
                        <div className="text-center py-32">
                            No matched datasets found.
                        </div>
                    )}
                    <Pagination
                        curPage={datasets.curr_page}
                        totalPages={datasets.total_pages}
                        changePageFunc={changePage}
                    />
                </>
            )}

            {confirmSubmit && (
                <ConfirmBox
                    action={() => handleSubmitApproval(confirmDataset)}
                    cancelAction={() => setConfirmSubmit(false)}
                >
                    Are you sure to submit the dataset 'on{" "}
                    {formatMonthStr(confirmDataset.dataset.month)} for{" "}
                    {lamppostDisplayName(
                        lamppostDict[confirmDataset.dataset.lamppost_id]
                    )}
                    ' for approval?
                </ConfirmBox>
            )}

            {confirmWithdraw && (
                <ConfirmBox
                    action={() => handleWithdrawApproval(confirmDataset)}
                    cancelAction={() => setConfirmWithdraw(false)}
                >
                    Are you sure to{" "}
                    <span className="text-red-700">
                        withdraw submitted approval
                    </span>{" "}
                    for the dataset 'on{" "}
                    {formatMonthStr(confirmDataset.dataset.month)} for{" "}
                    {lamppostDisplayName(
                        lamppostDict[confirmDataset.dataset.lamppost_id]
                    )}
                    ' ?
                </ConfirmBox>
            )}
        </>
    );
};

DataValidation.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};

const DatasetsTable = ({
    userProfile,
    datasets,
    lamppostDict,
    submitApproval,
    withdrawApproval
}) => {
    const navToValidate = (dataset, viewOnly) =>
        navigate(
            `/validation/validate?date=${dataset.month}01&lamppostId=${
                dataset.lamppost_id
            }${viewOnly ? "&viewOnly=1" : ""}`
        );

    const navToApprove = (dataset) =>
        navigate(
            `/validation/approve?month=${dataset.month}&lamppostId=${dataset.lamppost_id}`
        );

    const ActionBtn = ({ onClick, children }) => (
        <button className="btn-secondary my-1 mr-4" onClick={onClick}>
            {children}
        </button>
    );

    const ActionsTD = ({ idx, dataset }) => (
        <td>
            <ErrMsg msg={dataset.statusErr} />
            <ActionBtn
                onClick={() =>
                    navToValidate(dataset, dataset.status === APPROVING)
                }
            >
                {dataset.status === APPROVING ? "View" : "Validate"}
            </ActionBtn>
            {dataset.status === RAW || dataset.status === INTERIM ? (
                <ActionBtn onClick={() => submitApproval(idx, dataset)}>
                    Submit for Approval
                </ActionBtn>
            ) : (
                dataset.status === APPROVING && (
                    <>
                        <ActionBtn
                            onClick={() => withdrawApproval(idx, dataset)}
                        >
                            Withdraw Submitted
                        </ActionBtn>
                        {UserUtil.isSysAdmin(userProfile) && (
                            <ActionBtn onClick={() => navToApprove(dataset)}>
                                Approve / Reject
                            </ActionBtn>
                        )}
                    </>
                )
            )}
        </td>
    );

    const StatusText = ({ data }) => (
        <>
            {data.status === REJECTED ? (
                <div>
                    <div className="red">{data.status}</div>
                    <div className="text-xs">{data.justification}</div>
                </div>
            ) : (
                <span className={data.status === APPROVED ? "green" : ""}>
                    {data.status}
                </span>
            )}
        </>
    );

    return (
        <table className="w-full admin-table">
            <thead>
                <tr>
                    <th style={{ width: "8%" }}>Month</th>
                    <th style={{ width: "20%" }}>Lamppost</th>
                    <th style={{ width: "20%" }}>Status</th>
                    <th style={{ width: "12%" }}>Last Updated By</th>
                    <th style={{ width: "15%" }}>Last Updated (HKT)</th>
                    <th style={{ width: "25%" }}>Actions</th>
                </tr>
            </thead>
            <tbody>
                {datasets.datasets.map((d, idx) => (
                    <tr key={idx}>
                        <td>{formatMonthStr(d.month)}</td>
                        <td>
                            {lamppostDisplayName(lamppostDict[d.lamppost_id])}
                        </td>
                        <td>
                            <StatusText data={d} />
                        </td>
                        <td>{d.last_updated_by}</td>
                        <td>
                            {d.last_updated_at &&
                                formatDate(
                                    d.last_updated_at,
                                    timeFormats.dayTime
                                )}
                        </td>
                        <ActionsTD idx={idx} dataset={d} />
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
